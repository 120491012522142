import React, { useEffect, useState } from "react";
import { Link } from "gatsby";
import { Layout, SEO } from '../../components/structure';
import { Content, Hero } from "../../components/blocks"
import styled, { css } from "styled-components"
import { brand, font, mq } from "../../styles";
import GatsbyImage from "gatsby-image";
import { graphql, useStaticQuery } from "gatsby";


const Header = styled.div`


${ font.imports.sherika }

  width: 90%;
  max-width: 1100px;
  text-align: left;
  padding-top 80px;

  h2 {
    font-family: ${font.family.sherika};
    font-size: 56px;
    font-weight: 700;
    padding: 20px 0;
    color: ${brand.colors.pg_primary_dark};
    display: inline-block;
    
    @media only screen and ${mq.maxMd} {
      text-align: center;
      font-size: 42px;
    }

    .color-emphasis {
      color: ${brand.colors.pg_orange};
    }
  }

}
`;

const Copy = styled.div`
    margin-top: 50px;
    margin-bottom: 20px;
  p {
    color: ${brand.colors.pg_primary_dark};
    font-size: 22px;
    a {
      display: inline-block;
      color: ${brand.colors.pg_blue};
      cursor: pointer;
      text-decoration: underline;
    }
  } 
`;

const PrivacyPolicy = styled.div`
  width: 90%;
  max-width: 1100px;
  margin: auto;
`
const PrivacyPage = () => {


  return(
    <Layout>
    <SEO title="Security | Plusgrade" 
          description={'Plusgrade Security Terms'}
      />
      <Content whiteBG e2e>
        <PrivacyPolicy>
          <Header>
              <h2>Plusgrade <span className={"color-emphasis"}>Vulnerability Disclosure Program</span></h2>
          </Header>
          <Copy>
            <p>
            At Plusgrade, we take cyber security and privacy seriously, however we realize that no technology is perfect.  We are committed to protecting the data we receive from our partners and the personal data within our remit, which is why we offer a vulnerability disclosure program.  We believe that working with skilled security researchers across the globe is crucial in identifying weaknesses in any technology.  We care about  information security, and we're ready to work with security researchers to improve the security of our services. Plusgrade believes that ethical security research performed in good-faith provides an invaluable public service.
            </p>
            <p>
            We are excited for you to participate as a security researcher to help us identify vulnerabilities in our web applications.
            </p>
            <p>Plusgrade has partnered with Bugcrowd to manage the vulnerability disclosure program.  Vulnerabilities submitted to the program will be triaged, assessed and rated according to the <a href="https://bugcrowd.com/vulnerability-rating-taxonomy" target="_blank">Bugcrowd Vulnerability Rating Taxonomy</a>. Researchers must also follow Bugcrowd’s <a href="https://www.bugcrowd.com/resources/essentials/standard-disclosure-terms/">standard disclosure terms</a>.</p>
            <p>The Plusgrade program does not allow for public disclosure and researchers may not release information about vulnerabilities found in this program to the public.
</p>
<p>The Plusgrade vulnerability program can be accessed on Bugcrowd by following the link: <a href="http://bugcrowd.com/plusgrade-vdp-pro">bugcrowd.com/plusgrade-vdp-pro</a></p>
<p>Good luck, and happy hunting!</p>
          </Copy>
        </PrivacyPolicy>
      </Content>
  </Layout>
  );
  
}

export default PrivacyPage;

const QuestionSectionContainer = styled.div`
font-family: ${font.family.sherika};
pointer-events: none;
p {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 0 10px;
  font-size: 24px;
  font-weight: 700;
  color: ${brand.colors.pg_blue};
  margin-bottom: 10px;
  pointer-events: all;
  cursor: pointer;
  border-bottom: 1px solid #BDC4D1;
  padding-bottom: 10px;

  .expand-icon {
    color: #BDC4D1;
    font-size: 40px;
  }
}

.reveal-container {
  max-height: 0;
  height: auto;
  overflow: hidden;
  border: none;
  width: 95%;
  margin: 0 auto 20px;
   p {
    display: block;
    font-size: 20px;
    font-weight: 400;
    color: ${brand.colors.pg_primary_dark};
    margin: 10px 0;
  }

  .italized {
    font-style: italic;
    font-size: 20px;
  }

  a {
    color: ${brand.colors.pg_blue} !important;
    text-decoration: underline;
    pointer-events: all;
  }

  ol {
    margin-left: 1.75rem;
    margin-bottom: 20px;
    li {
      color: ${brand.colors.pg_blue};
      list-style: none;
      font-weight: 800;
      margin: 10px 0;
      font-size: 20px;

      ul > li {
        color: ${brand.colors.pg_primary_dark};
        font-weight: 400;
        font-size: 20px;
      }

      ul > li:before {
        content: '';
      }
    }

    li:before {
      content: counter(list, lower-alpha) ") ";
      counter-increment: list;
      font-size: 20px;
    }
  }
  ul {
    margin-left: 1.75rem;
    margin-bottom: 20px;
    li {
      color: ${brand.colors.pg_primary_dark};
      list-style: circle;
    }

    ul > li {
      list-style: square;
    }
  }
}

${props => props.open === true && css`
  p {
    border: none;
  }
  .reveal-container {
    max-height: 6000px;
  }
`}

`;

const LearnMoreSection = styled.div`
  .learn-more-clickable {
    color: ${brand.colors.pg_blue} !important;
    text-decoration: underline;
  }
  .learn-more-expandable {
    color: ${brand.colors.pg_primary_dark};
    height: auto;
    max-height: 0;
    width: 95%;
    margin: auto;

    a {
      display: block;
      pointer-events: all;
      cursor: pointer;
      text-decoration: underline;
      color: ${brand.colors.pg_blue};
    }
  }

  ${props => props.open === true && css`
    .learn-more-expandable {
      max-height: 6000px;
    }
  `}
`;



{/**
Question Section Component
Accepts
- Question prop = Clickable Header section question
- Answer Prop = Content of dropdown, accepts HTML. Check styled component here carefully as adding p, ul, or ol are pre-styled.
- learnMoreContent = Dropdown content of the 'learn more' section
*/}
const QuestionSection = ({question, answer, learnMoreContent}) => {

const [FAQOpen, setFAQSection] = useState(false);
const [learnMoreOpen, setLearnMore] = useState(false); 

  const pageQuery = useStaticQuery(graphql`
  query{
    plusIconGrey: file(base: {eq: "plus-grey.png"}){
      childImageSharp{
        fixed(width: 32, height: 32, webpQuality: 100) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }

    minusIconGrey: file(base: {eq: "minus-grey.png"}){
      childImageSharp{
        fixed(width: 32, height: 32, webpQuality: 100) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }

  }
`);

let plusIcon = <span className={"expand-icon"}>+</span>
let minusIcon = <span className={"expand-icon"}>-</span>
const openClose = () => {
  if (FAQOpen && learnMoreOpen){
    setLearnMore(false);
  }
  setFAQSection(!FAQOpen); 
}

const expandCompressLearnMore = () => {
  setLearnMore(!learnMoreOpen);
}
  return(
    <QuestionSectionContainer open={FAQOpen}>
      <p onClick={openClose}>{question} {FAQOpen ? minusIcon : plusIcon}</p>
      <div className={"reveal-container"}>
        {answer}
      {
        learnMoreContent ? 
        <LearnMoreSection open = {learnMoreOpen}>
          <p className={"learn-more-clickable"} onClick={expandCompressLearnMore}>Learn More</p>
          <div className={"learn-more-expandable"}>
            {learnMoreContent}
          </div>
      </LearnMoreSection>
      :
      <></>
      }
      </div>
    </QuestionSectionContainer>
  );
}
